import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import PriceCard from "../components/price-card";
import BackgroundPattern from "../images/Hex bg.svg";
import BackgroundPattern2 from "../images/Behind photo logo.svg";

const CyberSecurityPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "cyber-security" }) {
        pageFlexibleContent {
          pageFlexibleContent {
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
              fieldGroupName
              innerPageBannerHeading
              innerPageBannerSubHeading
              innerPageLink {
                target
                title
                url
              }
              innerPageBannerImage {
                sourceUrl
                altText
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_ItServices {
              fieldGroupName
              itServicesHeading
              itServicesDescription
              itServicesNotice
              itServices {
                itService
                itServiceContent
                itServiceLink {
                  target
                  title
                  url
                }
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_BottomImageContentSection {
              contentSectionHeading
              contentSectionDescription
              fieldGroupName
              bottomImage {
                sourceUrl
                altText
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection {
              fieldGroupName
              twoColumnContent
              twoColumnFlip
              twoColumnImage {
                sourceUrl
                altText
              }
              twoColumnButton {
                target
                title
                url
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_GetInTouch {
              fieldGroupName
              getInTouchHeading
            }
          }
        }
      }
      heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Cyber Security",
        item: {
          url: `${siteUrl}/cyber-security`,
          id: `${siteUrl}/cyber-security`,
        },
      },
    ],
  };

  const {
    pageData: {
      pageFlexibleContent: { pageFlexibleContent },
    },
  } = data;

  const getInTouch = () => {
    let heading = "Get in touch to find out how we can work together";
    if (pageFlexibleContent.length > 0) {
      const reviewSection = pageFlexibleContent.filter(
        (item) =>
          item.fieldGroupName ==
          "Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
      );
      if (reviewSection.length > 0) {
        heading = reviewSection[0].getInTouchHeading;
      }
    }
    return (
      <section
        style={{ zIndex: 3 }}
        className="pb-5 py-lg-7 position-relative"
        id="form"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Cyber Security | Cranfield IT Solutions: Computer Repair & Tech Support Sussex"
        description="Computer Repair & Tech Support."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/cyber-security`,
          title: "Cyber Security | Cranfield IT Solutions: Computer Repair & Tech Support Sussex",
          description: "Computer Repair & Tech Support.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {pageFlexibleContent.length > 0 &&
          pageFlexibleContent.map((pageInfo, i) => (
            <>
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner" && (
                <Hero
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: pageInfo.innerPageBannerHeading,
                      }}
                    ></span>
                  }
                  subHeading={pageInfo.innerPageBannerSubHeading}
                  buttonTarget={pageInfo.innerPageLink?.target}
                  buttonLink={pageInfo.innerPageLink?.url}
                  buttonTitle={pageInfo.innerPageLink?.title}
                  bannerImage={pageInfo.innerPageBannerImage.sourceUrl}
                  hideButton={!pageInfo.innerPageLink ?? true}
                  key={i}
                />
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_ItServices" && (
                <section className=" pb-5 pb-xl-7 position-relative">
                  <div
                    style={{ zIndex: 1 }}
                    className="position-absolute d-none d-lg-block start-50 translate-middle-x top--30 bottom-0"
                  >
                    <img alt="" className="" src={BackgroundPattern} />
                  </div>
                  <Container
                    style={{ zIndex: 2 }}
                    className="position-relative"
                  >
                    <Row className="">
                      <Col>
                        <h2 className="fs-1 mb-4 mb-lg-5">
                          {pageInfo.itServicesHeading}
                        </h2>
                        {pageInfo.itServicesDescription && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: pageInfo.itServicesDescription,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    {pageInfo.itServices.length > 0 && (
                      <Row className="h-100 g-5 g-lg-2 g-xl-5">
                        {pageInfo.itServices.map((serviceItem, j) => (
                          <PriceCard
                            key={j}
                            title={
                              <>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: serviceItem.itService,
                                  }}
                                />
                              </>
                            }
                            list={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: serviceItem.itServiceContent,
                                }}
                              />
                            }
                            buttonHeight="4.5rem"
                            buttonLink={serviceItem.itServiceLink.url}
                            buttonTarget={serviceItem.itServiceLink.target}
                            buttonTitle={serviceItem.itServiceLink.title}
                          />
                        ))}
                      </Row>
                    )}
                    {pageInfo.itServicesNotice && (
                      <Row>
                        <Col className="text-center mt-5">
                          <p>{pageInfo.itServicesNotice}</p>
                        </Col>
                      </Row>
                    )}
                  </Container>
                </section>
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_BottomImageContentSection" && (
                <section style={{ zIndex: 3 }} className="  position-relative">
                  <Container>
                    <Row>
                      <Col>
                        <h2 className="fs-1 mb-4 ">
                          {pageInfo.contentSectionHeading}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pageInfo.contentSectionDescription,
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg={4} md={6} className="text-center">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.ncsc.gov.uk/cyberessentials/overview"
                        >
                          <img
                            src={pageInfo.bottomImage?.sourceUrl}
                            alt={pageInfo.bottomImage?.altText}
                          />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection" && (
                <section className=" position-relative  ">
                  <Container className=" position-relative pt-7  ">
                    <div
                      style={{ zIndex: 1, left: "5rem", top: "8rem" }}
                      className="bg-black position-absolute d-none d-xl-block ps-5 ps-xl-8 text-end "
                    >
                      <img alt="" className="" src={BackgroundPattern2} />
                    </div>
                    <div
                      style={{ zIndex: 1, left: "5rem", top: "10rem" }}
                      className="bg-black position-absolute d-none d-lg-block d-xl-none ps-5 ps-xl-8 text-end "
                    >
                      <img
                        alt=""
                        style={{ maxHeight: "20rem" }}
                        src={BackgroundPattern2}
                      />
                    </div>

                    <Row
                      style={{ zIndex: 3 }}
                      className={`g-3 g-xl-7 position-relative align-items-center ${pageInfo.twoColumnFlip === "Flip Yes" ? 'flex-row-reverse' : '' }`}
                    >
                      <Col className="text-center" lg={{ span: 6 }}>
                        <div
                          className="d-none text-center d-lg-block d-flex justify-content-center "
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={pageInfo.twoColumnImage?.sourceUrl}
                            altText={pageInfo.twoColumnImage?.altText}
                          />
                          {/* <GatsbyImage
                            className="mx-auto"
                            image={
                              data.firewallImg?.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            alt={data.firewallImg?.altText}
                          /> */}
                        </div>

                        <div
                          className="position-relative  d-lg-none mb-5 d-flex justify-content-center   "
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={pageInfo.twoColumnImage?.sourceUrl}
                            altText={pageInfo.twoColumnImage?.altText}
                          />
                        </div>
                      </Col>
                      <Col lg={{ span: 6 }}  dangerouslySetInnerHTML={{
                            __html: pageInfo.twoColumnContent
                          }}>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </>
          ))}

        {getInTouch()}
      </Layout>
    </>
  );
};

export default CyberSecurityPage;
